#sandboxmodeswitchalert-modal .modal-dialog {
  margin-top: 100px;
}

#sandboxmodeswitchalert-modal .modal-header {
  border-bottom: none;
}

#sandboxmodeswitchalert-modal .modal-footer {
  border-top: none;
}

#sandboxmodeswitchalert-modal h4.modal-title {
  font-size: 24px;
}

#sandboxmodeswitchalert-modal .modal-content {
  padding: 5px 15px;
}
