div#sandbox-switcher-select-wrapper {
  width: 100%;
  display: grid;
  grid-gap: 0.33em;  
}

div#sandbox-switcher-select-wrapper.horiz {
  grid-template-columns: fit-content(1%) auto;
}

div#sandbox-switcher-select-wrapper.vert {
  grid-template-columns: auto;
}

div#sandbox-switcher-select-wrapper.horiz > div:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;  
}

div#sandbox-switcher-select-wrapper > div:nth-child(1) span[sie-tooltip] {
  top: -3px;
  padding-left: 0.25em;
}

div#sandbox-switcher-select-wrapper.vert > div:nth-child(3) {
  display: none;
}
/* add styles to prevent search box from causing page to jump */
div#sandbox-switcher-select-wrapper > div:nth-child(2){
  position: relative;
  width:100%;
  height:40px;
}

div#sandbox-switcher-select-wrapper > div:nth-child(2) > .sie-select{  
  position: absolute;
  width:100%;  
}

div#sandbox-switcher-select-wrapper > div:nth-child(4) > .sie-status{
  width: 100%;
}
